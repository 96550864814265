import React from "react";
import SEO from "../components/seo"
import Layout from "../components/layout";
import Image from "../components/image";
import Slidy from "../components/slidy";

const Ueber = () => {
    const sliderImages = [
        {
            image: "IMG_8010.jpg",
            alt: "Bienenstock",
        },
        {
            image: "IMG_8011.jpg",
            alt: "Bienenstock am Ast",
        },
        {
            image: "IMG_8012.jpg",
            alt: "Bienenstock am Baum",
        },
        {
            image: "IMG_8013.jpg",
            alt: "Bienenkiste",
        }
    ]

    return (
        <Layout>
            <SEO title="Über Uns" />
            <div className="textPage">
                <h1>Warum wir tun, was wir tun.</h1>
                <div className="textPageInfo">
                    <p>
                        <strong>Bienen sind wichtig für unseren Lebensraum.</strong>
                        <br/>
                        Deswegen übernahm ich von meinem Vater den Bienenbestand und bewirtschafte unsere Bienenvölker seither gemeinsam mit meiner Familie. Köstliches aus dem Bienenstock, echte Bienenwachskerzen und hausgemachte Marmeladens gehören unter anderem zu unseren Produkten.
                        <br/><br/>
                        Sie finden uns am Ortsrand von Halbenrain, ca. 6 km vor der Thermenstadt Bad Radkersburg.
                    </p>
                    <Slidy sliderImages={sliderImages} portrait={true} />
                </div>
            </div>
        </Layout>
    )
}

export default Ueber